import React from 'react'
import { connect } from 'react-redux'

const ProgresMain = props => {
  return (
    <div
      className="oprogress-container"
      style={{
        backgroundColor: props.theme === 'extra-dark' ? '#ffff' : 'var(--themeColor)'
      }}
    >
      <div
        style={{
          width: `${props.progress}%`,
          height: '20px',
          backgroundColor: '#2D3748',
          borderRadius: 20,
          transition: 'width 0.5s',
          margin: 5
        }}
      />
    </div>
  )
}
const mapStateToProps = state => ({
  theme: state.theme.action.theme
})
export default connect(mapStateToProps)(ProgresMain)
