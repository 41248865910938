import React, { useState } from 'react'
import '../ReusableCode/emlyMain.css'
import './index.css'
const RestoredComponent = ({ children, description, title, onSubmit, buttons, content, onCancel }) => {
  const [isContentVisible, setContentVisibility] = useState(false)

  const toggleContent = () => {
    setContentVisibility(!isContentVisible)
  }

  return (
    <div className={'orestore-component'} style={{ padding: children ? 10 : 0 }}>
      <div style={cardStyle}>
        <div className="op-title">{title}</div>

        {children ? (
          <div className={'orestore-title'}>
            {children && (
              <img
                style={{ width: 50, height: 50 }}
                src="https://www.jimphicdesigns.com/downloads/imgs-mockup/blue-hourglass.gif"
              />
            )}
            {description}
          </div>
        ) : (
          <div className="restore-description">{description}</div>
        )}
        {children}

        {isContentVisible && <div style={additionalContentStyle}>{content}</div>}
        {buttons && (
          <div
            style={{
              backgroundColor: '#fbfbfb',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: content ? 'space-between' : 'end'
            }}
          >
            {content && (
              <div className="em-row em-flex" style={iconContainerStyle} onClick={toggleContent}>
                {isContentVisible ? (
                  <i class="fa fa-arrow-circle-down ">
                    {' '}
                    <span className="fewer-more"> Fewer details</span>
                  </i>
                ) : (
                  <i class="fa fa-arrow-circle-up ">
                    <span className="fewer-more"> More details</span>
                  </i>
                )}
              </div>
            )}
            <div style={buttonContainerStyle}>
              <button style={{ width: 200 }} onClick={onSubmit} className={'em-btn'}>
                Restore
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

const cardStyle = {
  width: '700px',
  margin: 'auto',
  backgroundColor: '#ffffff',
  borderRadius: '8px',
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  position: 'relative'
}

const buttonContainerStyle = {
  display: 'flex',
  justifyContent: 'flex-end',
  paddingBottom: 10,
  paddingRight: '20px',
  paddingTop: '10px'
}

const iconContainerStyle = {
  padding: '0 20px',
  justifyContent: 'space-between',
  cursor: 'pointer'
}

const additionalContentStyle = {
  marginTop: '10px',
  fontSize: '14px',
  padding: '10px',
  borderRadius: '4px',
  paddingTop: '20px',
  paddingLeft: '20px',
  paddingRight: '20px'
}

export default RestoredComponent
