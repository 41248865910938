import React, { useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { getCurrentContextuserIdDetails } from '../Admin/AccountUtility'
import RestoredComponent from '../Progress/card'
import ProgresMain from '../Progress/ProgresMain'
import './index.css'
import { uberData } from './utils'

const UberContainer = props => {
  const [ubContainer, setUbContainer] = useState([])
  const intervalRef = useRef(null)

  const current_logged_user = getCurrentContextuserIdDetails(props.myUserAccountInfo.current_logged_user)

  const clearCurrentInterval = () => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current)
      intervalRef.current = null
    }
  }

  useEffect(() => {
    if (props.checkUberDeployment && !props.checkUberDeployment.isLoading) {
      setUbContainer(props.checkUberDeployment)
    }
  }, [props.checkUberDeployment])

  useEffect(() => {
    if (
      (props.checkUberDeployment && props.checkUberDeployment.status === 'DEPLOYING') ||
      props.checkUberDeployment.status === 'SUBMITTED' ||
      props.checkUberDeployment.status === 'INITIALISED' ||
      props.checkUberDeployment.status === 'VERIFYING'
    ) {
      clearInterval(intervalRef.current)

      intervalRef.current = setInterval(() => {
        fetchUberDeployment()
      }, 10000)
    }
    return () => clearCurrentInterval()
  }, [])

  const currentStep = uberData.find(i => i.name === ubContainer.status)

  const navigateTo = path => {
    const { history } = props
    history.push(path)
  }

  const fetchUberDeployment = () => {
    const api = props.api

    if (api === null) return

    const action = api.createFetchUberDeploymentRSAA(current_logged_user.orgId)

    Promise.resolve(props.dispatch(action)).then(response => {
      const hasError = response.type.toLowerCase().endsWith('failure')
      if (!hasError) {
        if (response.payload.status === 'DEPLOY_FAILED') {
          clearCurrentInterval()
          navigateTo('/orgInstance')
        } else if (response.payload.status === 'DEPLOY_SUCCESS') {
          clearCurrentInterval()
          if (current_logged_user.spaceId !== 'undefined') {
            navigateTo(
              `/${current_logged_user.orgId}/${current_logged_user.spaceId}/projects/${current_logged_user.spaceId}`
            )
          } else {
            navigateTo('/projects')
          }
        }
      } else {
        clearCurrentInterval()
        navigateTo('/orgInstance')
      }
    })
  }

  return ubContainer &&
    (ubContainer.status === 'DEPLOYING' ||
      ubContainer.status === 'SUBMITTED' ||
      ubContainer.status === 'INITIALISED' ||
      ubContainer.status === 'VERIFYING') ? (
    <div className="uber-navigator" onClick={() => navigateTo('/orgInstance')}>
      <div className="first-para" data-theme={props.curTheme.theme}>
        <RestoredComponent
          title={
            <p>
              Restoring <b className="org-name">{current_logged_user.orgIdName}</b>
            </p>
          }
          description={currentStep && currentStep.message}
          buttons={false}
        >
          <ProgresMain progress={currentStep && currentStep.progress} />
        </RestoredComponent>
      </div>
    </div>
  ) : (
    <></>
  )
}

const mapStateToProps = state => ({
  curTheme: state.theme && state.theme.action,
  api: state.api,
  checkUberDeployment: state.checkUberDeployment,
  myUserAccountInfo: state.myUserAccountInfo
})

export default withRouter(connect(mapStateToProps)(UberContainer))
