import { useKeycloak } from '@react-keycloak/web'
import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import detectOutsideEvent from '../../../components-examples/utils/detectOutsideEvent'
import { addTaskWindowTroughtApi } from '../../../redux/FrameWorkReducer'
import { emptyNotification } from '../../../redux/notificationAction'
import Dropdown from './Dropdown'

const MenuItems = ({ items, depthLevel, dispatch, disabled, showSwitch }) => {
  let ref = useRef()
  const [dropdown, setDropdown] = useState(false)

  const { keycloak, initialized } = useKeycloak()

  useEffect(() => {
    const handler = event => {
      if (dropdown && ref.current && !ref.current.contains(event.target)) {
        setDropdown(false)
      }
      try {
        if (
          event.target.getAttribute('id') === 'About' ||
          event.target.getAttribute('id') === 'Organizations' ||
          event.target.getAttribute('id') === 'Profile'
        ) {
          setTimeout(() => {
            setDropdown(false)
          }, [500])
        }
      } catch (error) {
        console.log('Error', error)
      }
    }
    document.addEventListener('mousedown', handler)
    document.addEventListener('touchstart', handler)
    return () => {
      // Cleanup the event listener
      document.removeEventListener('mousedown', handler)
      document.removeEventListener('touchstart', handler)
    }
  }, [dropdown])

  const onMouseEnter = () => {
    window.innerWidth > 960 && setDropdown(true)
  }

  const onMouseLeave = () => {
    window.innerWidth > 960 && setDropdown(false)
  }

  const impactRef = useRef()
  detectOutsideEvent(impactRef, () => handleClick())

  const handleClick = () => {
    if (dropdown) setDropdown(prev => !prev)
  }

  const logoutInSuperSet = () => {
    keycloak.logout()
  }

  if (!items.root)
    return (
      <>
        <li className="menu-items" ref={ref} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
          {items.submenu ? (
            <>
              <button
                type="button"
                aria-expanded={dropdown ? 'true' : 'false'}
                onClick={() => !disabled && setDropdown(prev => !prev)}
                aria-haspopup="menu"
              >
                {depthLevel > 0 ? <span>&laquo;</span> : ''} {items.title}
              </button>

              <Dropdown
                disabled={disabled}
                submenus={items.submenu}
                dropdown={dropdown}
                depthLevel={depthLevel}
                showSwitch={showSwitch}
              />
            </>
          ) : items.type === 'component' ? (
            items.title
          ) : items.title === 'Log out' ? (
            <button
              type="button"
              className="logout"
              onClick={() => {
                dispatch(addTaskWindowTroughtApi([]))
                dispatch(emptyNotification())
                setTimeout(() => {
                  // dispatch(setUserProfile(null))
                  // dispatch(setUserProfileID(null))
                })
                window.localStorage.setItem('notifications', null)
                logoutInSuperSet()
              }}
            >
              {items.title}
            </button>
          ) : items.type === 'Modal' ? (
            items.title
          ) : (
            <Link to={`${items.url}`} id={items.title} className={items.disabled && 'disabled-link'}>
              {items.title}
            </Link>
          )}
        </li>
      </>
    )
  else
    return (
      <li className="menu-items" ref={impactRef}>
        <>
          <button
            className="topbtn"
            type="button"
            aria-expanded={dropdown ? 'true' : 'false'}
            onClick={() => !disabled && setDropdown(prev => !prev)}
            aria-haspopup="menu"
            title={items.label}
          >
            {items.title}
          </button>
          <Dropdown
            disabled={disabled}
            dispatch={dispatch}
            submenus={items.submenu}
            dropdown={dropdown}
            depthLevel={depthLevel}
          />
        </>
      </li>
    )
}

export default MenuItems
