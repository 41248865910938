import React from 'react'
import ProfileMenu from '../Admin/MenuComponent/ProfileMenu'
import '../UserSignup/index.css'
import { ErrorScreen } from './ErrorScreen'
import './start.css'

const profileItems = [
  {
    title: 'U',
    root: true,
    label: 'User',
    submenu: [
      {
        title: 'Profile',
        url: '/profile',
        type: 'link',
        disabled: true
      },
      {
        title: 'Log out',
        type: 'link'
      }
    ]
  }
]

class Start extends React.Component {
  render() {
    return (
      <React.Fragment>
        <div className="start-org-creation">
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <div className="org-image-div">
              <div className="org-content-div">
                <img className="org-cr-logo" src={require('../../../images/onBoard/Elogo.png')} />

                <div className="org-content-main">
                  <img className="org-comma" src={require('../../../images/SignupImages/comma.png')} />
                  <p>
                    We instill trust by making AI transparent, allowing you to question and understand model predictions
                  </p>
                  <p>Monis Khan, Emly labs CEO</p>
                </div>
              </div>
            </div>

            <>
              <div className="em-org-cr-flex">
                <div className="em-row org-logout">
                  <div className="topicons">
                    <ProfileMenu disabled={false} menuItems={profileItems} dispatch={() => {}} showSwitch={() => {}} />
                  </div>
                </div>
                {this.props.error !== null ? (
                  <ErrorScreen buttonText={'Retry'} error={this.props.error} onResolve={this.props.onResolve} />
                ) : (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      flex: 1,
                      backgroundColor: '#fff',
                      paddingTop: 200
                    }}
                  >
                    <div className="centreLoadingSpinner" style={{ height: 'calc( 100vh - 800px )' }}>
                      <h3> Creating your organization</h3>
                      <img
                        src={require('../../../images/onBoard/Loading Bar.gif')}
                        style={{ width: 200, height: 200 }}
                      />
                    </div>
                  </div>
                )}
              </div>
            </>
          </div>
        </div>
      </React.Fragment>
    )
  }
}
export default Start
