import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import './Modal-Form.css'

class PopUp extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    const {
      title,
      buttonLabel,
      children,
      onExecution,
      onCancel,
      type,
      bottomButton = true,
      bottomButtonCancel = true,
      loadingCancel = false,
      bottonLabelCencel = 'Cancel',
      disabled = false,
      link = null,
      topWidth = false,
      width = '70%',
      fullScreen,
      appstore
    } = this.props
    const label = type === 'danger' ? (buttonLabel ? buttonLabel : 'Delete') : buttonLabel

    return (
      <React.Fragment>
        <div className={`popupMainContainer model-space ${appstore ? 'appStore-create' : ''}`}>
          <div
            className="popup-content"
            data-theme={
              this.props.theme && this.props.theme.action && this.props.theme.action.theme
                ? this.props.theme.action.theme
                : 'light'
            }
          >
            <div
              style={{ width: width, marginTop: topWidth ? 50 : 0 }}
              className={
                type === 'danger' ? 'popupMainContainer-all-content danger' : 'popupMainContainer-all-content success'
              }
            >
              <div className="modalHeader" style={{ backgroundColor: '#ffffff' }}>
                {!loadingCancel && (
                  <div className="header-icons">
                    {/* {<div className="pdf-style"> */}
                    {fullScreen}

                    {/* </div>} */}

                    <button className="close-button" onClick={onCancel} aria-hidden="true" aria-label="Close">
                      <i class="fa fa-times" aria-hidden="true"></i>
                    </button>
                  </div>
                )}
              </div>
              <>
                <div style={{ marginTop: 10 }}>
                  {title && <h3 className="currentOrg">{title}</h3>}
                  {children}
                </div>
                {bottomButton || bottomButtonCancel ? (
                  <div className={bottomButtonCancel ? 'modalFooter' : 'modalFooter-1'}>
                    {bottomButton && (
                      <button
                        className={disabled ? 'disabledInvite' : type === 'danger' ? 'dangerInvite' : 'successInvite'}
                        id={bottomButtonCancel ? 'invite-user' : 'invite-user-1'}
                        onClick={e => !disabled && onExecution(e)}
                      >
                        {link !== null ? (
                          <Link style={{ color: '#fff' }} to={link}>
                            {label}
                          </Link>
                        ) : (
                          label
                        )}
                      </button>
                    )}
                    {bottomButtonCancel && (
                      <button
                        style={{ color: '#000' }}
                        className={type === 'danger' ? 'dangerCancel' : 'successCancel'}
                        id={'cancel'}
                        onClick={onCancel}
                      >
                        {bottonLabelCencel}
                      </button>
                    )}
                  </div>
                ) : (
                  <></>
                )}
              </>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}
const mapStateToProps = state => ({
  theme: state.theme
})
export default connect(mapStateToProps)(PopUp)
