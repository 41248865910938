import React from 'react'
import { Logo } from '../../../../src/imagePaths'
import PopUp from '../PopUP'
import { NormalButton } from '../ReusableCode/EmlyButton'
import { LoaderBGWithinComponent } from '../utils'

const UserWelcome = props => {
  return (
    <PopUp
      width={'800px'}
      bottomButton={false}
      onCancel={() => {
        props.onCancel()
      }}
      bottomButtonCancel={false}
      onExecution={() => ''}
    >
      {props.isLoading && <LoaderBGWithinComponent />}
      <div
        className="em-row welcome-user em-flex"
        style={{ alignContent: 'space-between', justifyContent: 'space-between', alignItems: 'start' }}
        data-theme={props.datatheme && props.datatheme.action && props.datatheme.action.theme}
      >
        <div className="sec1">
          <div className="platform-logo" style={{ width: '150px' }}>
            <img src={Logo.datoin} style={{ width: '100%' }}></img>
          </div>
          {props.isFirstTime === false ? (
            <>
              <div className="W-header" style={{ fontSize: '25px' }}>
                <img
                  src={require('../../../images/onBoard/comma.png')}
                  style={{ width: 20, float: 'inline-start', marginRight: '3px' }}
                />
                <span style={{ fontSize: '25px' }}>Your Subscription </span>Has Ended
              </div>
              <h3>
                {props.isOwner
                  ? `Renew Now - It's super easy and quick`
                  : `Please contact your admin for futher details`}
              </h3>
              <h4>Click here to proceed with payment.</h4>
            </>
          ) : (
            <>
              <div className="W-header">
                <img
                  src={require('../../../images/onBoard/comma.png')}
                  style={{ width: 20, float: 'inline-start', marginRight: '3px' }}
                />
                Welcome To <span>Emly Labs</span>
              </div>
              <h3>{props.type === 'GREETINGS' ? 'Congratulations!' : 'Great Job On Creating Account!'}</h3>

              <h4>
                {props.type === 'GREETINGS'
                  ? 'You have successfully created an account. One last step, activate your services instances.'
                  : props.isOwner
                  ? `To begin using Emly Labs services, there's just one more step: setting up your subscription.`
                  : `Please contact your admin for futher details`}
              </h4>
            </>
          )}
          <div className="em-row">
            {props.type === 'GREETINGS' ? (
              <NormalButton
                className="em-btn"
                onClick={() => props.primaryAction()}
                title={props.title}
                style={{ width: '92%', float: 'right' }}
              >
                {props.title}
              </NormalButton>
            ) : (
              props.isOwner && (
                <NormalButton
                  className="em-btn"
                  onClick={() => props.primaryAction()}
                  title={props.isFirstTime === false ? 'Proceed' : props.title}
                  style={{ width: '92%', float: 'right' }}
                >
                  {props.isFirstTime === false ? 'Proceed' : props.title}
                </NormalButton>
              )
            )}
          </div>
        </div>
        <div className="sec-2">
          <div className="user-welcome" style={{ width: '350px' }}>
            <img src={Logo.UserWelcome} style={{ width: '100%' }}></img>
          </div>
        </div>
      </div>
    </PopUp>
  )
}

export default UserWelcome
