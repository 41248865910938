import React, { useState } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { OneUniqueLoaderBG } from '../../../api/components-examples/utils.js'
import { addNotification, showNotification } from '../../redux/notificationAction'
import PopUp from '../PopUP/index.js'
import { getCurrentOrganizationById } from '../utils/commonUtilityFunctions.js'
import { getCurrentContextuserIdDetails } from './AccountUtility'

const UberServiceAlert = props => {
  const [prompt, setPromt] = useState(false)
  const [loader, setLoader] = useState(false)

  const showModal = it => {
    setPromt(true)
  }

  const intializeUberDeployment = () => {
    setPromt(false)
    setLoader(true)
    let current_logged_user = getCurrentContextuserIdDetails(props.myUserAccountInfo.current_logged_user)
    const currentOrganization = getCurrentOrganizationById(props.myUserAccountInfo, current_logged_user.orgId)
    const api = props.api
    let params = {
      projectId: current_logged_user.spaceId
    }
    const action = api.createUberDeploymentRSAA(
      {
        body: JSON.stringify({
          deploymentInfo: {
            services: []
          },
          orgInfo: {
            orgName: currentOrganization.name
          }
        })
      },
      current_logged_user.orgId,
      params
    )

    Promise.resolve(props.dispatch(action)).then(response => {
      const hasError = response.type.toLowerCase().endsWith('failure')
      if (!hasError) {
        setTimeout(() => {
          fetchUberDeployment()
        }, 3000)
      } else {
        setLoader(false)
        if (
          response.payload &&
          response.payload.response &&
          response.payload.response.status === 403 &&
          response.payload.response.message.includes('Your plan subscription is invalid')
        ) {
          props.dispatch(addNotification(response.payload.response.message, 'error', false))
        } else {
          props.dispatch(addNotification(response.payload.response.message, 'error'))
        }
        props.dispatch(showNotification(true))
      }
    })
  }

  const navigateTo = path => {
    const { history } = props
    history.push(path)
  }

  const fetchUberDeployment = () => {
    let current_logged_user = getCurrentContextuserIdDetails(props.myUserAccountInfo.current_logged_user)

    const api = props.api

    if (api === null) return
    const action = api.createFetchUberDeploymentRSAA(current_logged_user.orgId)
    Promise.resolve(props.dispatch(action)).then(result => {
      if (result.type.endsWith('SUCCESS')) {
        if (result.payload.status === 'DEPLOY_FAILED') {
          setLoader(false)
        }
        if (result.payload.status === 'DEPLOY_SUCCESS') {
          setLoader(false)
          if (current_logged_user.spaceId !== 'undefined') {
            navigateTo(`${current_logged_user.orgId}/${current_logged_user.spaceId}/apps`)
          } else {
            navigateTo('/projects')
          }
        }
        if (
          result.payload.status === 'DEPLOYING' ||
          result.payload.status === 'SUBMITTED' ||
          result.payload.status === 'INITIALISED' ||
          result.payload.status === 'VERIFYING'
        ) {
          setLoader(false)
          navigateTo('/orgInstance')
        }
      } else {
        setLoader(false)
        navigateTo('/orgInstance')
      }
    })
  }

  let current_logged_user = getCurrentContextuserIdDetails(props.myUserAccountInfo.current_logged_user)

  return (
    <div
      data-theme={props.theme && props.theme.action && props.theme.action.theme ? props.theme.action.theme : 'light'}
    >
      {prompt && (
        <PopUp
          width={'500px'}
          loadingCancel={true}
          buttonLabel={'Restore'}
          onCancel={() => {
            setPromt(!prompt)
          }}
          onExecution={() => {
            intializeUberDeployment()
          }}
        >
          <h3 style={{ fontWeight: 'bold', textAlign: 'center' }} className="heading-part">
            Are you sure you want to Restore ?
          </h3>
        </PopUp>
      )}

      {loader && <OneUniqueLoaderBG />}
      {props.isSubscriptionCheck &&
      !props.isSubscriptionCheck.isLoading &&
      props.isSubscriptionCheck.subscriptionStatus === 'BUY_PLAN' &&
      props.myProfile &&
      !props.myProfile.isLoading &&
      props.myProfile.roles !== 'owner' ? (
        <div className="instance_down">
          Your current subscription plan is not valid. Please contact the account owner to address this issue.
        </div>
      ) : props.checkUberDeployment &&
        !props.myProfile.undefined &&
        !props.checkUberDeployment.isLoading &&
        (props.checkUberDeployment.status === 'DEPLOY_FAILED' || props.checkUberDeployment.status === 'NOT_FOUND') ? (
        <div className="instance_down">
          {current_logged_user && current_logged_user.orgIdName} is not currently active,
          <div className="restore txtHover" onClick={() => showModal(current_logged_user.orgId)}>
            Restore
          </div>
        </div>
      ) : (
        ''
      )}
    </div>
  )
}

const mapStateToProps = state => ({
  api: state.api,
  myProfile: state.myProfile,
  checkUberDeployment: state.checkUberDeployment,
  theme: state.theme,
  isSubscriptionCheck: state.isSubscriptionCheck,
  myUserAccountInfo: state.myUserAccountInfo
})

export default withRouter(connect(mapStateToProps)(UberServiceAlert))
