import { Breadcrumb } from 'patternfly-react'
import React from 'react'
import { Link } from 'react-router-dom'
import { NormalButton } from '../ReusableCode/EmlyButton'

//@Author Aadil Masavir
//Aadilmasavir@datoin.com
//datoin.com
//09 December 2022

const NavigationBreadCrumb = props => {
  return (
    <div className="admin-title-breadcrumb-parent">
      <div className="admin-panel-breadcrumb">
        <Breadcrumb title={false}>
          {(() => {
            let breadCrumbLinks = []
            let activeBreadCrumb = null
            props.breacrumbarray.length > 1 &&
              props.breacrumbarray.map(it => {
                if (it.activeLink) {
                  activeBreadCrumb = it
                } else {
                  if (it.type && it.type == 'METHOD') {
                    breadCrumbLinks.push(
                      <Breadcrumb.Item>
                        <NormalButton
                          className="linkName btn-link"
                          onClick={() => it.executeMethod && it.executeMethod(it)}
                        >
                          {it.linkTitle}
                        </NormalButton>
                      </Breadcrumb.Item>
                    )
                  } else {
                    breadCrumbLinks.push(
                      <Breadcrumb.Item>
                        <Link to={{ pathname: it.link, fetchParam: props, state: { datasetId: it.datasetId } }}>
                          <NormalButton
                            className="linkName btn-link"
                            onClick={() => it.callingMethod && it.callingMethod()}
                          >
                            {it.linkTitle}
                          </NormalButton>
                        </Link>
                      </Breadcrumb.Item>
                    )
                  }
                }
              })
            breadCrumbLinks.push(
              <Breadcrumb.Item active>{activeBreadCrumb && activeBreadCrumb.linkTitle}</Breadcrumb.Item>
            )
            return breadCrumbLinks.map(item => item)
          })()}
        </Breadcrumb>
      </div>
    </div>
  )
}

export default NavigationBreadCrumb
