const customErrorMiddleware = store => next => action => {
  if (action.type.toLowerCase().endsWith('failure')) {
    const isType =
      action.type.toUpperCase().startsWith('SI_FE_UB_DEPLOYMENT_STATUS') ||
      action.type.toUpperCase().startsWith('FETCH_UBER_DEPLOYMENT_STATUS')

    if (
      !isType &&
      action &&
      action.payload &&
      action.payload.response &&
      action.payload.response.deploymentType === 'UBER_DEPLOYMENT' &&
      action.payload.response.status === 'NOT_FOUND'
    ) {
      store.dispatch({ type: 'FETCH_DOWN_UBER_SUCCESS', payload: { status: 'UBERISDOWN' } })
    }
  }

  return next(action)
}
export default customErrorMiddleware
