import PropTypes from 'prop-types'
import React, { lazy, Suspense } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { getCurrentContextuserIdDetails } from './api/components-examples/Admin/AccountUtility'
import './api/components-examples/Admin/admin.css'
import BillingPopUp from './api/components-examples/Admin/BilliingPopUp'
import ProjectAccessRemoveAlert from './api/components-examples/Admin/ProjectAccessRemoveAlert'
import UberServiceAlert from './api/components-examples/Admin/uberServiceAlert'
import AlertBox from './api/components-examples/AlertComponent'
import { mobileRoutes, visibleRoutes } from './api/components-examples/Routes/routes'
import UberContainer from './api/components-examples/UberService/UberContainer'
import { OneUniqueLoaderBG } from './api/components-examples/utils'
import {
  allowedFeatures,
  getOrgChoices,
  manageOrgChoice,
  sUserCredentials
} from './api/components-examples/utils/commonUtilityFunctions'
import MainErrorBoundaryParent from './api/components-examples/utils/ErrorBoundary/MainErrorBoundaryParent'
import Welcome from './api/components-examples/Welcome'
import './api/components-examples/Welcome/index.css'
import UserWelcome from './api/components-examples/Welcome/UserWelcome'
import { addTaskWindowTroughtApi, setProjectAccessRemoved } from './api/redux/FrameWorkReducer'
import { addNotification, showNotification } from './api/redux/notificationAction'
import { setUserProfileID } from './api/redux/UserProfile'
import { setTheme } from './api/redux/UserTheme'
import MessageBox from './api/ResponsiveMessage'
import './dashboard-style.css'
import './Dashboard.css'
import './dt-icons.css'
import './emlyDashboard.css'
import './emlyMobile.css'
import './index.css'
import { MainContent } from './MainContent'
import MainDashboardWrapper from './MainDashboardWrapper'
import MainError from './MainDashboardWrapper/MainErrorComponent'
import { PublicRoutes } from './PublicRoutes'
import SSE from './SSE'
import './styles.css'

const UserInfo = React.createContext()

const SignInRequired = lazy(() => import('../src/KeyCloak/SignInRequired'))
const NoSignInRequired = lazy(() => import('../src/KeyCloak/NoSignInRequired'))

class Dashboard extends React.Component {
  constructor() {
    super()
    this.state = {
      activeItem: null,
      errorMessage: null,
      defaultPath: null,
      navCollapse: false,
      appStatusCollapse: true,
      loadError: null,
      loaderImage: null,
      loading: false,
      userLoading: false,
      isMobile: false,
      billing: false,
      billingAddress: null,
      buyPlan: false,
      upgradeplanloading: false,
      retryCount: 0,
      welcomScreen: false,
      currentOrganization: [],
      intializeUberLoader: false,
      userNotFound: false
    }
  }

  handleResize = () => {
    const current_logged_user = getCurrentContextuserIdDetails(
      this.props.myUserAccountInfo && this.props.myUserAccountInfo.current_logged_user
    )

    if (mobileRoutes(current_logged_user).includes(this.props.location.pathname) === false) {
      this.setState({
        isMobile: window.innerWidth <= 768
      })
    } else {
      this.setState({
        isMobile: false
      })
    }
  }

  navigateTo = path => {
    const { history } = this.props
    history.push(path)
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleResize)
    this.handleResize()
    this.fetchMyAccountInfo()
    this.fetchDatoinServices()
    this.fetchHelpLinks()
    this.windowFocusEventCapture()
  }

  checkAllowedSize = () => {
    const api = this.props.api
    let current_logged_user = getCurrentContextuserIdDetails(this.props.myUserAccountInfo.current_logged_user)
    if (api === null) return
    if (current_logged_user.spaceId !== undefined || current_logged_user.spaceId !== null)
      this.props.dispatch(api.createDatasetUploadMaxSizeRSAA(current_logged_user))
  }

  upgradePlan = planId => {
    this.setState({ upgradeplanloading: true })
    let current_logged_user = getCurrentContextuserIdDetails(this.props.myUserAccountInfo.current_logged_user)

    const bodyparams = {
      successUrl: `${window.REACT_APP_SELF_URL}/organizations/billings?paymentSuccess=78dfr030`,
      cancelUrl: `${window.REACT_APP_SELF_URL}/organizations/billings?paymentFail=s67830-09`,
      productId: planId.value,
      paymentMode: `subscription`
    }

    const { api } = this.props
    if (api == null) return
    Promise.resolve(this.props.dispatch(api.createCheckOutSessionRSAA(current_logged_user, bodyparams))).then(
      response => {
        const t = response.type.toLowerCase()
        const hasError = t.endsWith('failure')
        if (!hasError) {
          window.location = response.payload.stripe && response.payload.stripe.url ? response.payload.stripe.url : '/'
          this.setState({ upgradeplanloading: false })
        } else {
          this.props.dispatch(
            addNotification(
              `${
                response.payload && response.payload.response && response.payload.response.message
                  ? response.payload.response.message
                  : window.REACT_APP_ERROR_MESSAGE
              }`,
              'danger',
              false
            )
          )
          this.props.dispatch(showNotification(true))
          this.setState({ upgradeplanloading: false })
        }
      }
    )
  }

  updatePlan = () => {
    let current_logged_user = getCurrentContextuserIdDetails(this.props.myUserAccountInfo.current_logged_user)
    fetch(`${window.REACT_APP_GATEWAY_API_URL}/v3/org/${current_logged_user.orgId}/update_plan`, {
      method: 'POST',
      headers: { Authorization: `Bearer ${this.props.api.keycloakToken()}`, 'Content-Type': 'application/json' },
      body: JSON.stringify({ returnUrl: `${window.REACT_APP_SELF_URL}/billings` }),
      credentials: 'include'
    })
      .then(r => r.json().then(data => ({ status: r.status, payload: data })))
      .then(response => {
        if (response.status === 200) {
          this.setState({ billingAddress: response.payload.stripe.url, billing: true })
        }
      })
      .catch(err => {
        console.log(err)
      })
  }

  fetchUberDeployment = silent => {
    let current_logged_user = getCurrentContextuserIdDetails(this.props.myUserAccountInfo.current_logged_user)
    const api = this.props.api

    if (api === null) return
    if (typeof current_logged_user !== 'object' || current_logged_user === null) return

    const action = api.createFetchUberDeploymentRSAA(current_logged_user.orgId, silent)
    Promise.resolve(this.props.dispatch(action)).then(result => {
      if (result.type.endsWith('SUCCESS')) {
        if (result.payload.status !== 'DEPLOY_SUCCESS') {
          if (
            visibleRoutes.includes(this.props.location.pathname) &&
            (this.props.checkUberDeployment.status === 'DEPLOYING' ||
              this.props.checkUberDeployment.status === 'SUBMITTED' ||
              this.props.checkUberDeployment.status === 'INITIALISED' ||
              this.props.checkUberDeployment.status === 'VERIFYING')
          ) {
            this.navigateTo('/orgInstance')
            return
          }
          if (!visibleRoutes.includes(this.props.location.pathname)) {
            this.navigateTo('/orgInstance')
            return
          }
        }
        if (result.payload.status === 'DEPLOY_SUCCESS' && silent === undefined) {
          this.checkAllowedSize(current_logged_user)
        }
      } else {
        if (!visibleRoutes.includes(this.props.location.pathname)) {
          this.navigateTo('/orgInstance')
          return
        }
      }
    })
  }

  getPath = () => {
    return this.props.location.pathname
  }

  fetchDatoinServices() {
    const api = this.props.api
    //Don't do anything if api does not exist.
    if (api == null) return
    const action = api.createFetchDatoinservicesRSAA()
    this.props.dispatch(action)
  }

  fetchMyProfile(orgId, spaceId) {
    this.fetchBuildInfo(orgId)
    this.setState({ loading: true })
    this.setState({ loaderImage: 'profile' })
    const api = this.props.api
    //Don't do anything if api does not exist.
    if (api == null) return
    const action = api.createFetchMyProfileRSAA(orgId, spaceId)
    Promise.resolve(this.props.dispatch(action)).then(response => {
      const hasError = response.type.toLowerCase().endsWith('failure')

      if (hasError && spaceId !== 'undefined') {
        if (
          response.payload.status === 404 &&
          response.payload.response.message !== undefined &&
          response.payload.response.message.startsWith('No role found for user having user')
        ) {
          this.props.dispatch(setProjectAccessRemoved(true))
          this.setState({ projectAccessRemoved: true, loading: false })
        } else {
          this.props.dispatch(setProjectAccessRemoved(false))
          this.setState({ loadError: true, loading: false })
        }
      } else {
        this.props.dispatch(setProjectAccessRemoved(false))
        this.setState({ loading: false, loaderImage: null })
        this.fetchPermissionMatrix()
        this.fetchUberDeployment()
        this.tasks()
        this.subscriptionCheck()
      }
    })
  }

  fetchBuildInfo = () => {
    const current_logged_user = getCurrentContextuserIdDetails(this.props.myUserAccountInfo.current_logged_user)
    const api = this.props.api
    //Don't do anything if api does not exist.
    if (api == null) return
    const action = api.createFetchVersionInfoRSAA(current_logged_user.orgId)
    this.props.dispatch(action)
  }

  tasks = () => {
    let current_conf = getCurrentContextuserIdDetails(this.props.myUserAccountInfo.current_logged_user)

    const action = this.props.api.createTasksRSAA(current_conf)
    Promise.resolve(this.props.dispatch(action)).then(response => {
      const hasError = response.type.toLowerCase().endsWith('failure')
      if (!hasError) {
        if (response.payload.notifications !== undefined && response.payload.notifications.length > 0) {
          const data = response.payload.notifications
            .filter(i => i.type === 'JOB')
            .map(i => {
              return {
                ...i,
                time: new Date().getTime()
              }
            })
          this.props.dispatch(addTaskWindowTroughtApi(data))
        }
      }
    })
  }

  intializeUberStore = () => {
    this.setState({ intializeUberLoader: true })
    let current_logged_user = getCurrentContextuserIdDetails(this.props.myUserAccountInfo.current_logged_user)
    const api = this.props.api
    const action = api.createUberDeploymentRSAA(
      {
        body: JSON.stringify({
          deploymentInfo: {
            services: []
          },
          orgInfo: {
            orgName: this.state.currentOrganization.name
          }
        })
      },
      current_logged_user.orgId,
      {}
    )
    Promise.resolve(this.props.dispatch(action)).then(response => {
      const hasError = response.type.toLowerCase().endsWith('failure')
      if (!hasError) {
        setTimeout(() => {
          this.fetchUberDeployment()
        }, 3000)
      } else {
        this.props.dispatch(
          addNotification(
            response.payload && response.payload.response && response.payload.response.message,
            'error',
            false
          )
        )
        this.props.dispatch(showNotification(true))
      }
      setTimeout(() => {
        this.setState({ intializeUberLoader: false })
        try {
          this.addInLStorage(current_logged_user, 'TRUE')
        } catch (error) {
          console.log('Error setting value in Localstorage', error)
        }
      }, 2500)
    })
  }

  addInLStorage(current_logged_user, value) {
    manageOrgChoice(window.localStorage.OrgChoice, current_logged_user.orgId, 'accountWelcome', value)
  }

  subscriptionCheck() {
    let current_logged_user = getCurrentContextuserIdDetails(this.props.myUserAccountInfo.current_logged_user)
    const api = this.props.api
    //Don't do anything if api does not exist.
    if (api == null) return
    const action = api.getOrgSubscriptionCheckRSAA(current_logged_user)
    Promise.resolve(this.props.dispatch(action)).then(response => {
      const hasError = response.type.toLowerCase().endsWith('failure')
      if (!hasError) {
        const currentOrganization = this.getCurrentOrganiztion(current_logged_user.orgId)
        if (currentOrganization) {
          this.setState({ currentOrganization: currentOrganization })
        }

        if (
          response.payload &&
          response.payload.subscriptionStatus &&
          response.payload.subscriptionStatus === 'PAYMENT_EXPIRED'
        ) {
          this.updatePlan()
        } else if (
          response.payload &&
          response.payload.subscriptionStatus &&
          response.payload.subscriptionStatus === 'BUY_PLAN'
        ) {
          this.setState({ buyPlan: true })
        } else if (
          response.payload &&
          response.payload.subscriptionStatus &&
          response.payload.subscriptionStatus === 'OK'
        ) {
          if (currentOrganization && currentOrganization.spaces && currentOrganization.spaces.length === 0) {
            this.setState({ welcomScreen: true })
          }
        }
      } else {
        if (this.state.retryCount < 3) {
          this.setState({ retryCount: this.state.retryCount + 1 })
          setTimeout(() => {
            this.subscriptionCheck()
          }, [1500])
        } else {
          console.error('Max retry attempts reached')
        }
      }
    })
  }

  subscriptionUsage() {
    let current_logged_user = getCurrentContextuserIdDetails(this.props.myUserAccountInfo.current_logged_user)
    const api = this.props.api
    //Don't do anything if api does not exist.
    if (api == null) return
    const action = api.createFetchMySubscriptionPlanUsageRSAA(current_logged_user)
    this.props.dispatch(action)
  }

  fetchMyAccountInfo() {
    this.setState({ userLoading: true })
    const api = this.props.api
    if (api == null) return
    const action = api.createFetchUserAccountInfo()
    Promise.resolve(this.props.dispatch(action)).then(response => {
      if (response.payload.current_logged_user !== undefined) {
        this.props.dispatch(setUserProfileID(response.payload.current_logged_user))
      } else {
        this.props.dispatch(setUserProfileID('error'))
      }
      this.setState({ userLoading: false })
      const hasError = response.type.toLowerCase().endsWith('failure')
      if (!hasError) {
        if (response.payload.rolesUser) {
          const current_logged_user = getCurrentContextuserIdDetails(response.payload.current_logged_user)
          if (current_logged_user !== 'NOCACHE' && current_logged_user !== 'NEWUSER') {
            this.setCurrentContext(current_logged_user)
            this.fetchMyProfile(current_logged_user.orgId, current_logged_user.spaceId)
          } else {
            const userResponse = response.payload.rolesUser.organizations[0]
            const switchTo = {
              orgId: userResponse.id,
              orgIdName: userResponse.title,
              spaceId: userResponse.spaces[0] && userResponse.spaces[0].id ? userResponse.spaces[0].id : 'undefined',
              spaceIdName:
                userResponse.spaces[0] && userResponse.spaces[0].name ? userResponse.spaces[0].name : 'No Project Yet'
            }
            sUserCredentials(
              window.localStorage.defaultProfileSetting,
              response.payload.current_logged_user,
              switchTo,
              true
            )
            const currentContextLoggedUser = getCurrentContextuserIdDetails(response.payload.current_logged_user)
            this.setCurrentContext(currentContextLoggedUser)
            this.fetchMyProfile(currentContextLoggedUser.orgId, currentContextLoggedUser.spaceId)
          }
        }
        this.subscriptionUsage()
      } else {
        if (response && response.payload && response.payload.status && response.payload.status === 404) {
          this.setState({ userNotFound: true, userLoading: false })
        } else {
          this.setState({ loadError: true, userLoading: false })
        }
      }
    })
  }

  fetchHelpLinks() {
    const api = this.props.api
    //Don't do anything if api does not exist.
    if (api == null) return
    const action = api.createFetchHelpLinksRSAA()
    this.props.dispatch(action)
  }

  fetchPermissionMatrix() {
    let current_logged_user = getCurrentContextuserIdDetails(this.props.myUserAccountInfo.current_logged_user)
    const api = this.props.api
    if (api == null) return
    const action = api.fetchPermissionMatrixObjectRSAA(current_logged_user)
    this.props.dispatch(action)
  }

  setCurrentContext = value => {
    this.setState({ loaderImage: 'usrContext' })
    const api = this.props.api
    if (api == null) return
    const action = api.getCurrenttContextRSAA(value)
    this.props.dispatch(action)
  }

  fetchTheme() {
    let local = this.props.theme

    const theme = window.localStorage.getItem('localTheme')
    this.props.dispatch(setTheme(theme === null ? 'light' : theme))
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.api !== prevProps.api) {
      this.fetchDatoinServices()
      this.fetchHelpLinks()
      this.fetchMyAccountInfo()
      this.fetchTheme()
    }

    if (
      this.props &&
      this.props.location &&
      this.props.location.pathname &&
      this.props.location.pathname !== prevProps.location.pathname
    ) {
      this.handleResize()
    }

    if (prevProps.myProfile !== this.props.myProfile && !this.props.myProfile.isLoading) {
      const current_logged_user = getCurrentContextuserIdDetails(this.props.myUserAccountInfo.current_logged_user)
      let thisPath = `/${current_logged_user.orgId}/${current_logged_user.spaceId}/projects/${current_logged_user.spaceId}`
      if (current_logged_user.spaceId === 'undefined') {
        thisPath = `/projects`
      }
      this.setState({
        defaultPath: thisPath
      })
    }

    if (
      this.props.checkUberDeployment &&
      prevProps.checkUberDeployment !== this.props.checkUberDeployment &&
      !this.props.checkUberDeployment.isLoading &&
      this.props.checkUberDeployment.status !== 'DEPLOY_SUCCESS'
    ) {
      const isNewUser =
        this.props.myUserAccountInfo.current_logged_user !== undefined &&
        this.props.myUserAccountInfo.rolesUser === undefined
          ? true
          : this.props.myUserAccountInfo.rolesUser !== undefined &&
            this.props.myUserAccountInfo.rolesUser.organizations[0].spaces.length === 0
          ? true
          : false
      isNewUser === false &&
        this.setState({
          defaultPath: '/orgInstance'
        })
    }

    if (
      this.props.checkUberDeployment !== prevProps.checkUberDeployment &&
      this.props.checkUberDeployment.status === 'DEPLOY_SUCCESS'
    ) {
      const isNewUser =
        this.props.myUserAccountInfo.current_logged_user !== undefined &&
        this.props.myUserAccountInfo.rolesUser === undefined
          ? true
          : this.props.myUserAccountInfo.rolesUser !== undefined &&
            this.props.myUserAccountInfo.rolesUser.organizations[0].spaces.length === 0
          ? true
          : false
      const currentUrl = window.location.href
      const samepageOrgPage = currentUrl.search('projects') === -1
      if (isNewUser && samepageOrgPage) {
        window.location = '/projects'
      }
    }

    if (
      this.props.uberIsDown &&
      !this.props.uberIsDown.isLoading &&
      this.props.uberIsDown.status === 'UBERISDOWN' &&
      this.props.uberIsDown !== prevProps.uberIsDown
    ) {
      this.navigateTo('/orgInstance')
    }
  }

  getCurrentOrganiztion = orgId => {
    if (
      this.props.myUserAccountInfo &&
      this.props.myUserAccountInfo.rolesUser &&
      this.props.myUserAccountInfo.rolesUser.organizations
    ) {
      const currentOrganization = this.props.myUserAccountInfo.rolesUser.organizations.reduce((acc, next) => {
        if (next.id === orgId) {
          return next
        } else {
          return acc
        }
      }, [])
      return currentOrganization
    } else {
      return null
    }
  }

  getCurrentSpace = (spaces, spaceId) => {
    const currentSpace = spaces.reduce((acc, next) => {
      if (next.id === spaceId) {
        return next.advancedMode !== undefined ? next.advancedMode : false
      } else {
        return acc
      }
    }, [])
    return currentSpace
  }

  onNavCollapse = () => {
    this.setState({ navCollapse: !this.state.navCollapse })
  }

  collapseNav = () => {
    this.setState({ navCollapse: true })
  }

  openNav = () => {
    this.setState({ navCollapse: false })
  }

  onAppStatusCollapse = () => {
    this.setState({ appStatusCollapse: !this.state.appStatusCollapse })
  }

  collapseAppStatus = () => {
    this.setState({ appStatusCollapse: false })
  }

  openAppStatus = () => {
    this.setState({ appStatusCollapse: true })
  }

  checkConfigurationChange = () => {
    if (this.props.myProfile) {
      let current_logged_user = getCurrentContextuserIdDetails(this.props.myUserAccountInfo.current_logged_user)
      if (typeof current_logged_user === 'object') {
        if (
          (this.props.currentContext && this.props.currentContext.orgId !== current_logged_user.orgId) ||
          this.props.currentContext.spaceId !== current_logged_user.spaceId
        ) {
          window.location = '/'
        }
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize)
  }

  windowFocusEventCapture = () => {
    let that = this
    try {
      document.addEventListener('visibilitychange', function () {
        if (!document.hidden && that.props.myUserAccountInfo.current_logged_user !== undefined) {
          that.checkConfigurationChange()
          that.fetchUberDeployment(true)
        }
      })
    } catch (error) {}
  }

  getAllowedFeatures = () => {
    const current_logged_user = getCurrentContextuserIdDetails(this.props.myUserAccountInfo.current_logged_user)
    const currentOrganization = this.getCurrentOrganiztion(current_logged_user.orgId)
    if (currentOrganization && currentOrganization.spaces && currentOrganization.spaces.length === 0) {
      return []
    } else if (currentOrganization && currentOrganization.spaces && currentOrganization.spaces.length > 0) {
      const isAdvanced = this.getCurrentSpace(currentOrganization.spaces, current_logged_user.spaceId)
      return allowedFeatures(isAdvanced)
    } else {
      return []
    }
  }

  getActivateStatus() {
    const current_logged_user = getCurrentContextuserIdDetails(this.props.myUserAccountInfo.current_logged_user)
    const currentValue = getOrgChoices(current_logged_user.orgId, 'accountWelcome')
    const accountWelcome = currentValue !== undefined && currentValue !== null ? currentValue : null
    return accountWelcome
  }

  render() {
    const { myUserAccountInfo } = this.props

    const isNewUser =
      myUserAccountInfo.current_logged_user !== undefined && myUserAccountInfo.rolesUser === undefined ? true : false

    if (isNewUser && JSON.parse(atob(this.props.api.keycloakToken().split('.')[1])).org_name !== undefined) {
      return (
        <MainErrorBoundaryParent>
          <Welcome history={this.props.history} />
        </MainErrorBoundaryParent>
      )
    }
    if (this.state.loadError) {
      return (
        <div className="mainDashboardConatiner">
          <MainDashboardWrapper location={this.props.location}>
            <MainError />
          </MainDashboardWrapper>
        </div>
      )
    }

    const userName =
      this.props.persisted.userInformation !== null && this.props.persisted.userInformation.name !== undefined
        ? this.props.persisted.userInformation.name
        : ''
    return (
      <React.Fragment>
        <Suspense fallback={<Loader text={null} />}>
          <NoSignInRequired {...this.props}>
            <PublicRoutes />
          </NoSignInRequired>

          <SignInRequired {...this.props}>
            <div className="mainDashboardConatiner">
              <MainDashboardWrapper
                items={this.getAllowedFeatures()}
                activeItem={this.state.activeItem}
                history={this.props.history}
                myProfile={this.props.myProfile}
                location={this.props.location}
                changeCurrentTab={this.changeCurrentTab}
                myApps={this.props.myApps}
                myUserAccountInfo={myUserAccountInfo}
                myTemplates={this.props.myTemplates}
                checkUberDeployment={this.props.checkUberDeployment}
                myReccTemplates={this.props.myReccTemplates}
                dispatch={this.props.dispatch}
                api={this.props.api}
                error={this.state.theme}
                dataset={this.props.dataset}
              >
                <MainErrorBoundaryParent history={this.props.history} error={this.state.loadError}>
                  {this.props.persisted.userInformation !== null &&
                    this.props.persisted.userInformation.sub !== undefined && (
                      <SSE
                        id={this.props.persisted.userInformation.sub}
                        api={this.props.api}
                        history={this.props.history}
                      />
                    )}
                  <div
                    style={{ display: 'flex', flexDirection: 'column' }}
                    className={this.props.collapse ? 'right-body-panel collapsed' : 'right-body-panel'}
                  >
                    {this.state.upgradeplanloading && <OneUniqueLoaderBG />}

                    {this.props.project_access_removed === true && <ProjectAccessRemoveAlert />}

                    <UberServiceAlert />

                    {this.getPath() !== '/orgInstance' && <UberContainer />}

                    <AlertBox />
                    {this.state.billing && (
                      <>
                        <UserWelcome
                          onCancel={() => this.setState({ billing: !this.state.billing })}
                          isLoading={this.state.intializeUberLoader}
                          primaryAction={() => {
                            if (this.state.billingAddress) {
                              window.location = this.state.billingAddress
                            } else {
                              this.setState({ buyPlan: true })
                            }
                          }}
                          datatheme={this.props.theme}
                          type="BILLING"
                          title={'Start'}
                          isOwner={
                            this.props.myProfile && this.props.myProfile.roles && this.props.myProfile.roles === 'owner'
                              ? true
                              : false
                          }
                          isFirstTime={
                            this.state.currentOrganization &&
                            this.state.currentOrganization.spaces &&
                            this.state.currentOrganization.spaces.length === 0
                              ? true
                              : false
                          }
                        />
                      </>
                    )}
                    {this.state.buyPlan &&
                      this.props.userProjectPermissions &&
                      !this.props.userProjectPermissions.isLoading &&
                      this.props.userProjectPermissions.org_billing === true && (
                        <BillingPopUp
                          setUpgradePlans={() => this.setState({ buyPlan: false })}
                          upgradePlan={this.upgradePlan}
                        />
                      )}

                    {this.state.welcomScreen &&
                      this.getActivateStatus() === null &&
                      this.props.checkUberDeployment &&
                      !this.props.checkUberDeployment.isLoading &&
                      this.props.checkUberDeployment.status === 'NOT_FOUND' && (
                        <UserWelcome
                          onCancel={() => this.setState({ welcomScreen: !this.state.welcomScreen })}
                          isLoading={this.state.intializeUberLoader}
                          primaryAction={this.intializeUberStore}
                          datatheme={this.props.theme}
                          type="GREETINGS"
                          title={'Activate'}
                          isFirstTime={true}
                        />
                      )}

                    {this.state.userLoading === false && this.state.loading === false ? (
                      this.state.isMobile ? (
                        <MessageBox />
                      ) : (
                        <MainContent
                          paths={this.getAllowedFeatures()}
                          services={this.props.datoinServices}
                          myUserAccountInfo={myUserAccountInfo}
                          error={this.props.apiError}
                          projectInfoKey={this.props.projectInfoKey}
                          defaultPath={
                            this.props.myUserAccountInfo &&
                            this.props.myUserAccountInfo.message &&
                            this.props.myUserAccountInfo.message.startsWith('No organizations found for user having')
                              ? '/organizations'
                              : this.state.defaultPath
                          }
                          history={this.props.history}
                          collapseNav={this.collapseNav}
                          openNav={this.openNav}
                          collapseAppStatus={this.collapseAppStatus}
                          openAppStatus={this.openAppStatus}
                          currentPath={this.props.location.pathname}
                          myProfile={this.props.myProfile}
                          userNotFound={this.state.userNotFound}
                        />
                      )
                    ) : (
                      <div className="centreLoadingSpinner" style={{ height: 'calc( 100vh - 50px )' }}>
                        <h3>{`Hey ${userName} We're setting up your workspace. This shouldn't take long 😉`}</h3>
                        <img
                          src={require('./images/Admin-Panel-Assets/BuildLoading.gif')}
                          style={{ width: 200, height: 200 }}
                        />
                      </div>
                    )}
                  </div>
                </MainErrorBoundaryParent>
              </MainDashboardWrapper>
            </div>
          </SignInRequired>
        </Suspense>
      </React.Fragment>
    )
  }
}

Dashboard.propTypes = {
  api: PropTypes.object,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
  api: state.api,
  project_access_removed: state.emlyFrameWork.project_access_removed,
  projectInfoKey: state.emlyFrameWork.projectInfoKey,
  apiError: state.apiError,
  myProfile: state.myProfile,
  myHelpLinks: state.myHelpLinks,
  datoinServices: state.datoinServices,
  bgStatus: state.bgStatus,
  myApps: state.myApps,
  myTemplates: state.myTemplates,
  myReccTemplates: state.myReccTemplates,
  checkUberDeployment: state.checkUberDeployment,
  currentContext: state.currentContext,
  myUserAccountInfo: state.myUserAccountInfo,
  dataset: state.emlycreateFetchMyNewDatasetsRSAAReducer.dataset,
  collapse: state.emlyFrameWork.collapse,
  persisted: state.userProfilePersisted,
  userProjectPermissions: state.userProjectPermissions,
  theme: state.theme,
  uberIsDown: state.uberIsDown
})

export default withRouter(connect(mapStateToProps)(Dashboard))
export { UserInfo }

export const Loader = ({ text }) => {
  return (
    <div className="centreLoadingSpinner" style={{ height: 'calc( 100vh - 50px )' }}>
      <h3>{text}</h3>
      <img src={require('./images/onBoard/Loading Bar.gif')} style={{ width: 200, height: 200 }} />
    </div>
  )
}
